import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { AboutBody } from "../components/about-body"

const AboutPage = (props) => {
    return (
        <Layout>
            <Seo
                title="About Dahlia Homes - Sell Your House Fast In Sacramento, CA"
                description="Do you need to sell your house in Sacramento? Dahlia Homes can help make the process fast and easy 
                and help you sell your Sacramento House"
                location={props.location.pathname}
                keywords={["Dahlia Homes", "Sacramento Home Buyer"]}
            />
            <div>
                <AboutBody />
            </div>
        </Layout>
    )
}
export default AboutPage